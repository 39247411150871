import React from 'react';
import PropTypes from 'prop-types'

const FooterMin = (props) => {

    const currentYear = new Date().getFullYear();
    let classnamefooter = (props.fixedBottom)?  "container fixed-bottom" : "container";

    return (
        <footer className="footer-area-min   footer-custom bg-color-1f1e2e">
            <div className={ classnamefooter }>
                <div className="footer-bottom-area-min" >
                    <div className="row align-items-center rockwell-font">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright &copy;{currentYear} <strong>Mutant Money Society</strong> All rights reserved</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul>

                                <li>

                                    <a href="/terms_of_service" className="rockwell-font">Terms & Conditions</a>

                                </li>
                                <li>

                                    <a href="/disclaimer" className="rockwell-font">Disclaimer</a>

                                </li>
                                <li>

                                    <a href="/getnfts" className="rockwell-font"  >How to Buy</a>


                                </li>



                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </footer>
    );
}

FooterMin.propTypes = {
    fixedBottom: PropTypes.bool
  }

export default FooterMin;
