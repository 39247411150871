// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
//  old import nekojs from './contracts/Nekoxs.json'

import mutantmoneysociety from './../contracts/Mutantmoneysociety.json'


import axios from 'axios';



import { RecoilRoot } from 'recoil'
import { ToastProvider } from 'react-toast-notifications';

import NavbarTwoCustom from './../components/_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import GoTop from './../components/_App/GoTop';  //   <NavbarTwoCustom />
import Whitelisting from './../components/Common/Whitelisting';
import FooterMin2 from './../components/_App/FooterMin2';



const LoaddingW3 = (props) => {
  return (
    <div><p>Loadding...</p></div>
  )
}

//////////////////////////////////////////////////////////
///changed to page interacting with the smart contract
//////////////////////////////////////////////////////////

class WhiteListingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      account: '',
      contract: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: props.currentPrice,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      date: new Date(),
      tokenURI: "",
      hideBuySellBox: false,
      vipAccessPrice: 10000000000000001,
      vipWhitelisted: false,

    };
    this.updateAccount=this.updateAccount.bind(this);
  }




  //mounting the main Element
  async componentDidMount() {

      // WE NEED THIS, DO NOT UNCOMMENT

     await this.loadWeb3(this.props.dispatch)

  }



  updateAccount(account_current){

    this.setState({account: account_current})
  }



  //network and web3 logic

  async loadWeb3(dispatch) {
    //console.log("loadWeb3")
    if (window.ethereum) {

      //console.log("Starting up....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()


      if (typeof accounts[0] !== 'undefined') {
        //console.log("current logged account -->",accounts[0])

        this.setState({ account: accounts[0], balance: 0, web3: web3})





      } else {
        window.alert('Please login with MetaMask')
      }


      //load contracts
      try {


        //console.log("mutantmoneysociety.networks[netId].address  ->",mutantmoneysociety.networks[netId].address)

        const contract = new web3.eth.Contract(mutantmoneysociety.abi, mutantmoneysociety.networks[netId].address)  // comes from ABI json file

        this.setState({ contract }) /// shortcut for {contract : contract}


        //check if that account is whitelisted
        const vipWhitelisted = await contract.methods.whitelist(this.state.account).call().catch(error => {

        });

        this.setState({ vipWhitelisted })
        //console.log("getting info from whitelisting page...", this.state.vipWhitelisted )
        //console.log("done..." )

      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  render() {


    return (
      <React.Fragment>
        <RecoilRoot>
 
          <ToastProvider autoDismiss autoDismissTimeout={10000}>
            <Whitelisting authenticity_token={this.props.authenticity_token}  
            hideBuySellBox={this.state.hideBuySellBox} 
            vipAccessPrice={this.state.vipAccessPrice} 
            contract={this.state.contract} 
            account={this.state.account}  
            vipwhitelistingon={this.props.vipwhitelistingon}
            updateAccount={this.updateAccount} 
            dispatch={this.props.dispatch} 
            S3_ADDRESS={this.props.S3_ADDRESS}  
          />
          </ToastProvider>
          <GoTop />
          <FooterMin2 fixedBottom={false} />
        </RecoilRoot>
      </React.Fragment>
    );
  }
}

export default WhiteListingPage
